import { faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AgoraVideoPlayer } from 'agora-rtc-react'
import { Col, Row } from 'antd'
import { uniqBy } from 'lodash'

export const Videos = ({ users, tracks, uid, patient }) => {
  return (
    <Row style={{ height: 400 }}>
      <Col span={12} style={{ height: '100%' }}>
        <AgoraVideoPlayer className='vid' style={{ height: '100%', width: '100%' }} videoTrack={tracks[1]} />
      </Col>
      {uniqBy(users, 'uid').map((user) => {
        if (user.videoTrack && user.uid !== uid) {
          return (
            <Col span={12} style={{ height: '100%' }} key={uid}>
              <AgoraVideoPlayer
                className='vid'
                videoTrack={user.videoTrack}
                style={{ height: '100%', width: '100%' }}
              />
            </Col>
          )
        } else {
          return null
        }
      })}
      {users.length < 1 ? (
        patient ? (
          <div
            style={{
              width: '50%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgba(16, 140, 225, 1)'
            }}
          >
            <div style={{ padding: 5, borderRadius: 10, height: 30, color: '#fff' }}>
              <FontAwesomeIcon icon={faUser} style={{ color: 'white', fontSize: 100 }} />
            </div>
          </div>
        ) : (
          <div
            style={{
              width: '50%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgba(16, 140, 225, 1)'
            }}
          >
            <div style={{ padding: 5, borderRadius: 10, height: 30, color: '#fff' }}>
              <FontAwesomeIcon icon={faUser} style={{ color: 'white', fontSize: 100 }} />
            </div>
          </div>
        )
      ) : undefined}
    </Row>
  )
}

import React, { useEffect } from 'react'

import { Layout, Space, message } from 'antd'
import { Header } from 'antd/es/layout/layout'
import { MenuOutlined } from '@ant-design/icons'
import { batch, useDispatch, useSelector } from 'react-redux'
import { isSignInWithEmailLink, onAuthStateChanged, signInWithEmailLink } from 'firebase/auth'
import { useNavigate, useLocation } from 'react-router-dom'
import { BrowserView, MobileView, deviceDetect } from 'react-device-detect'

import AuthEnums from '../../custom/Auth/utils/enums'
import authReducer from '../../custom/Auth/utils/reducer'
import { firebaseAuth } from '../..'
import { findAgiliteUser, updateAgiliteUser } from '../../custom/Auth/utils/utils'
import coreReducer from '../utils/reducer'

// Components
import MetaClinicDashboard from '../../custom/Dashboard/meta-clinic-dashboard'
import EmailSignUpForm from '../../custom/Auth/components/FormComponents/emailSignUpForm'
// import { readConfig } from '../../custom/Admin/config/utils/utils'
import { readServices } from '../../custom/Admin/services/utils/utils'
import { readClinics } from '../../custom/Admin/clinics/utils/utils'
import clinicsCRUDReducer from '../../custom/Admin/clinics/utils/reducer'
import { readConfig } from '../../custom/Admin/config/utils/utils'
import { readTerms } from '../../custom/Admin/terms-conditions/utils/utils'
import servicesReducer from '../../custom/Admin/services/utils/reducer'
import { readNetCashConfig, readProfessions, readRoles } from '../utils/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsers } from '@fortawesome/free-solid-svg-icons'
import Dependants from '../../custom/Profile/components/dependants/dependants-list-view'
import CoreEnums from '../utils/enums'
import { handleError } from '../../custom/lib/utils'
import { readVirtualServices } from '../../custom/Admin/virutal-services/utils/utils'
import virtualServicesReducer from '../../custom/Admin/virutal-services/utils/reducer'
import MedicalHistoryReducer from '../../custom/Medical Vault/medical-history/utils/medical-history-reducer'
import { getPatient } from '../../custom/Medical Vault/medical-history/utils/utils'
import { getSubscriptions } from '../../custom/Subscriptions/utils/utils'
import SubscriptionsReducer from '../../custom/Subscriptions/utils/reducer'
import MetaIconLogo from '../../assets/images/landing-image.png'
import { readSystemUsers } from '../../custom/Admin/system-users/utils/utils'
import DependentsReducer from '../../custom/Profile/components/dependants/utils/depentdents-reducer'

const Toolbar = (props) => {
  const coreState = useSelector((state) => state.core)
  const authState = useSelector((state) => state.auth)
  const appTitle = useSelector((state) => state.core.title)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    async function init() {
      const entity = coreState.entity
      let agiliteUser = null
      let email = null
      let providerId = null

      try {
        // Confirm the link is a sign-in with email link.
        if (isSignInWithEmailLink(firebaseAuth, window.location.href)) {
          let pathArray = null

          email = window.localStorage.getItem(AuthEnums.localStorage.EMAIL_FOR_SIGN_IN)

          if (!email) email = window.prompt('Please provide your email for confirmation')

          await signInWithEmailLink(firebaseAuth, email, window.location.href)
          window.localStorage.removeItem(AuthEnums.localStorage.EMAIL_FOR_SIGN_IN)

          pathArray = window.location.pathname.split('/').filter((p) => p !== '') || []

          if (pathArray.length > 0) {
            navigate(`/${pathArray[0]}${AuthEnums.routes.ROOT}`)
          } else {
            navigate(`${AuthEnums.routes.ROOT}`)
          }
        }

        // Check if User Auth state has changed or is complete
        onAuthStateChanged(firebaseAuth, async (user) => {
          try {
            // Only run the following logic when the url doesn't contain any routes

            // !IMPORTANT TODO: WE NEED TO ACCOMMODATE ENTITY PATHS AS WELL NOT JUST ROOT PATH
            if (!['/checkin', '/payment-succeeded', '/payment-failed'].includes(window.location.pathname)) {
              dispatch(authReducer.actions.setLoading(true))

              if (user) {
                batch(() => {
                  dispatch(authReducer.actions.setUser(user))
                  dispatch(authReducer.actions.setLoggedIn(true))
                })

                providerId = firebaseAuth.currentUser.providerData[0].providerId

                // If the user signed in with google or email link we need to check if they have a profile in Agilit-e
                if (providerId === AuthEnums.signInTypes.GOOGLE || providerId === AuthEnums.signInTypes.PASSWORD) {
                  // Check if User exists in Agilit-e
                  if (user.phoneNumber) {
                    agiliteUser = await findAgiliteUser({ phoneNumber: user.phoneNumber })
                  } else if (user.email) {
                    agiliteUser = await findAgiliteUser({ email: user.email })
                  }

                  if (providerId === AuthEnums.signInTypes.GOOGLE || providerId === AuthEnums.signInTypes.PASSWORD) {
                    if (agiliteUser) {
                      if (
                        entity &&
                        agiliteUser.extraData.role.type !== 'patient' &&
                        agiliteUser.extraData.role.type !== 'super_admin' &&
                        !agiliteUser.extraData.entities.includes(entity._id)
                      ) {
                        dispatch(authReducer.actions.setUser(null))
                        dispatch(authReducer.actions.setLoggedIn(false))
                        throw new Error(CoreEnums.errorMessages.ENTITY_NOT_AUTH)
                      }

                      if (!agiliteUser.extraData?.isActive) {
                        throw new Error('Account Disabled')
                      }
                    }
                  }

                  if (!agiliteUser || !agiliteUser.idNo) {
                    dispatch(authReducer.actions.setCompleteEmailSignUp(true))

                    if (agiliteUser) {
                      dispatch(authReducer.actions.setAgiliteUser(agiliteUser))
                    }
                  } else {
                    dispatch(authReducer.actions.setAgiliteUser(agiliteUser))
                  }
                } else {
                  // Phone Number Sign In
                  if (user.phoneNumber) {
                    agiliteUser = await findAgiliteUser({ phoneNumber: user.phoneNumber })
                  }

                  if (agiliteUser) {
                    if (!agiliteUser.extraData?.isActive) {
                      throw new Error('Account Disabled')
                    }

                    dispatch(authReducer.actions.setAgiliteUser(agiliteUser))
                  }
                }

                if (agiliteUser && !agiliteUser.extraData.inviteStatus) {
                  dispatch(authReducer.actions.setCompleteEmailSignUp(true))
                }

                batch(() => {
                  dispatch(authReducer.actions.setLoading(false))

                  if (agiliteUser) {
                    if (
                      agiliteUser.extraData.role.type === 'admin' ||
                      agiliteUser.extraData.role.type === 'reception'
                    ) {
                      // Admin Role
                      if (agiliteUser.extraData.role.type === 'admin') {
                        dispatch(coreReducer.actions.enableDisableRightMenu(true))
                      }

                      if (deviceDetect().isBrowser) {
                        dispatch(coreReducer.actions.hideTabs(false))
                      } else {
                        dispatch(coreReducer.actions.hideTabs(true))
                      }
                    } else {
                      // User Role
                      if (agiliteUser.extraData.role.type !== 'medical_professional') {
                        dispatch(coreReducer.actions.enableDisableLeftMenu(true))
                        dispatch(coreReducer.actions.hideTabs(true))
                      }
                    }

                    // In dev mode, enable left menu always
                    if (process.env.REACT_APP_ENVIRONMENT === 'dev') {
                      dispatch(coreReducer.actions.enableDisableRightMenu(true))
                    }
                  }

                  handleDependencies(agiliteUser)

                  dispatch(coreReducer.actions.enableDisableTabs(true))
                  dispatch(coreReducer.actions.setRootContent(MetaClinicDashboard))
                })

                if (agiliteUser && agiliteUser.extraData.role.type === 'patient') {
                  if (!agiliteUser.extraData.entities.includes(entity._id)) {
                    await updateAgiliteUser(agiliteUser._id, {
                      ...agiliteUser,
                      extraData: {
                        ...agiliteUser.extraData,
                        entities: [...agiliteUser.extraData.entities, entity._id]
                      }
                    })
                  }
                }
              } else {
                batch(() => {
                  dispatch(authReducer.actions.setLoading(false))
                  dispatch(authReducer.actions.setUser(null))
                  dispatch(authReducer.actions.setLoggedIn(false))
                })
              }
            }
          } catch (e) {
            message.error(handleError(e, true))
            dispatch(authReducer.actions.setLoading(false))
          }
        })
      } catch (e) {
        message.error(handleError(e, true))
        dispatch(authReducer.actions.setLoading(false))
      }
    }

    if (coreState.entity) {
      init()
    }
    // eslint-disable-next-line
  }, [coreState.entity])

  const handleDependencies = async (agiliteUser) => {
    try {
      const [
        rolesResponse,
        professionsResponse,
        servicesResponse,
        virtualServicesResponse,
        clinicsResponse,
        termsResponse,
        netCashConfigResponse,
        configResponse,
        medicalHistoryResponse,
        subscriptionsResponse,
        dependantsResponse
      ] = await Promise.all([
        readRoles(),
        readProfessions(),
        readServices({ isActive: true }),
        readVirtualServices({ isActive: true }),
        readClinics({ isActive: true }),
        readTerms(),
        readNetCashConfig(),
        readConfig(),
        getPatient(),
        agiliteUser && agiliteUser._id ? getSubscriptions({ userRef: agiliteUser._id }) : undefined,
        agiliteUser && agiliteUser._id ? readSystemUsers({ mainMemberId: agiliteUser._id }) : undefined
      ])

      batch(() => {
        dispatch(authReducer.actions.setRoles(rolesResponse))
        dispatch(authReducer.actions.setProfessions(professionsResponse))
        dispatch(servicesReducer.actions.setRecords(servicesResponse))
        dispatch(virtualServicesReducer.actions.setRecords(virtualServicesResponse))
        dispatch(clinicsCRUDReducer.actions.setRecords(clinicsResponse))
        dispatch(authReducer.actions.setTerms(termsResponse))
        dispatch(authReducer.actions.setNetCashConfig(netCashConfigResponse))
        dispatch(MedicalHistoryReducer.actions.setPatient(medicalHistoryResponse))
        dispatch(SubscriptionsReducer.actions.setSubscriptions(subscriptionsResponse))
        dispatch(DependentsReducer.actions.setRecords(dependantsResponse))
      })

      if (configResponse.length > 0) {
        const config = configResponse[0]
        dispatch(authReducer.actions.setConfig(config))
      } else {
        message.warning('No Configurations found')
      }
    } catch (e) {
      message.error(handleError(e, true))
    }
  }

  const handleOpenFamilyAccounts = () => {
    dispatch(
      coreReducer.actions.addTab({
        key: CoreEnums.tabKeys.DEPENDANTS,
        closable: true,
        label: CoreEnums.tabTitles.DEPENDANTS,
        children: <Dependants />
      })
    )
  }

  const getActiveTabTitle = () => {
    const tabKeysEnum = CoreEnums.tabKeys
    const titleEnum = CoreEnums.tabTitles

    // Find the key associated with the given value
    const key =
      Object.keys(tabKeysEnum).find((k) => tabKeysEnum[k] === coreState.tabNavigation?.activeKey) ||
      Object.keys(tabKeysEnum).find((k) => tabKeysEnum[k] === coreState.tabNavigation?.activeKey.split('_')[0])

    // Use the found key to get the title

    return key ? titleEnum[key] : 'Title Not Found'
  }

  return (
    <Layout
      style={
        authState.loggedIn
          ? { margin: 0, position: 'sticky', top: 0, zIndex: 10 }
          : { margin: 0, display: 'none', position: 'sticky', top: 0, zIndex: 10 }
      }
    >
      <Header
        style={{
          fontSize: '13pt',
          color: 'white',
          paddingLeft: 20,
          paddingRight: 20
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', gap: 20, alignItems: 'center' }}>
            <MobileView>
              <img src={MetaIconLogo} style={{ height: 30, display: 'block' }} alt='Meta-Health Logo' />
            </MobileView>
            {props.leftMenuEnabled && !deviceDetect().isBrowser ? (
              <MenuOutlined style={{ float: 'left', cursor: 'pointer', fontSize: 24 }} onClick={props.onLeftMenuOpen} />
            ) : null}
            <BrowserView>
              {authState?.agiliteUser?.extraData?.role.type === 'super_admin' ? (
                <h2 style={{ margin: 0 }}>Super Admin Portal</h2>
              ) : (
                <h2 style={{ margin: 0 }}>{appTitle} Portal</h2>
              )}
            </BrowserView>
            <MobileView>
              <p style={{ fontSize: 18 }}>{getActiveTabTitle()}</p>
            </MobileView>
          </div>
          <BrowserView>
            <center>
              <p style={{ fontSize: 18 }}>{getActiveTabTitle().toUpperCase()}</p>
            </center>
          </BrowserView>
          {location.pathname !== '/checkin' || authState.loggedIn ? (
            <div style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
              {!deviceDetect().isMobile && authState?.agiliteUser?.extraData.role.type === 'patient' ? (
                <Space
                  style={{ cursor: 'pointer', alignItems: 'center' }}
                  onClick={() => {
                    handleOpenFamilyAccounts()
                  }}
                >
                  <FontAwesomeIcon icon={faUsers} /> Family Accounts
                </Space>
              ) : null}
              {/* {props.themeToggle()} */}
              {props.customMenus.content}
              {/* {props.rightMenuEnabled ? (
                <>
                  <Divider type='vertical' style={{ background: '#ffffff' }} />
                  <MenuOutlined
                    style={{
                      cursor: 'pointer'
                    }}
                    onClick={props.onRightMenuOpen}
                  />
                </>
              ) : null} */}
            </div>
          ) : null}
        </div>
      </Header>
      <EmailSignUpForm />
    </Layout>
  )
}

export default Toolbar

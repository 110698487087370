import Agilite from 'agilite'
import { handleError } from '../../lib/utils'

const sandboxApiKey =
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VySWQiOiI2NjU0M2RkODc1OTA0NTAwMDg4NGQ4ZWQiLCJ0ZWFtSWQiOiJtZXRhaGVhbHRoX3NhbmRib3giLCJlbnRpdHlJZCI6bnVsbCwidGltZXN0YW1wIjoiMjAyNC0wNS0yOFQwODowMTo1Ni45MzNaIn0.873KJG77s6m9-KAQl3gGqX_9MBkLO-o41Vm82Z2fZxc'

const prodApiKey =
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VySWQiOiI2M2VmNzAwMzYyZDE3MTAwMGE0NzZjZDQiLCJ0ZWFtSWQiOiJtZXRhaGVhbHRoX2RldiIsImVudGl0eUlkIjpudWxsLCJ0aW1lc3RhbXAiOiIyMDIzLTAyLTE4VDEyOjE2OjA1LjQwN1oifQ.Jl4Rk5mPTJUQYV80YFtEjmhfR-Q01DThucvEHw3sYLI'

// Use PROD to push to QA
// Use SANDBOX to push to Prod

const agilite = new Agilite({
  apiServerUrl: process.env.REACT_APP_AGILITE_API_URL,
  apiKey: sandboxApiKey
})

export const tmpUploadFile = (fileName, contentType, file, persistFile = false, isPublic) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        let response = null

        response = await agilite.Files.uploadFile(fileName, contentType, file, persistFile, isPublic)
        resolve(response.data)
      } catch (e) {
        reject(handleError(e, true))
      }
    })()
  })
}
